import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listQuizzes } from "../../api/quiz";
import { createExternalToken } from "../../api/auth";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { roles } from "../../utils/constants";
import "./Test.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { v4 as uuid } from "uuid";
import TextField from "@mui/material/TextField";
import { Select, MenuItem } from "@mui/material";

export default function Test() {
  const [quizzes, setquizzes] = useState([]);
  const [loading, loadingSet] = useState(false);
  const [error, errorSet] = useState("");
  const [userId, setUserId] = useState(1);
  const [role, setRole] = useState("ADMIN");
  const [instanceId, setInstanceId] = useState("1234");
  const [registrationId, setRegistrationId] = useState(uuid().substring(0, 8));
  let navigate = useNavigate();

  const handleSSO = async (role, id, route, duplicateQuizId = null) => {
    const { accountId, token } = await createExternalToken(
      role,
      id,
      route,
      duplicateQuizId,
      instanceId,
      registrationId,
      userId
    );
    navigate(`/sso/?accountId=${accountId}&token=${token}`);
  };

  useEffect(() => {
    const getQuizzes = async () => {
      try {
        loadingSet(true);
        const quizList = await listQuizzes(
          process.env.REACT_APP_TEST_ACCOUNT_ID
        );
        if (quizList.error) errorSet(quizList.error);
        if (Array.isArray(quizList)) setquizzes(quizList);
      } catch (e) {
        errorSet(e.message || "Error Fetching Quizzes");
      } finally {
        loadingSet(false);
      }
    };
    getQuizzes();
  }, []);

  if (loading) return <div>...loading</div>;

  return (
    <div className="test-root">
      <div className="quiz-table-header">
        <h3>Quizzes</h3>
        <IconButton
          size="large"
          aria-label="Edit Quiz"
          color="success"
          onClick={() => handleSSO(role, null, "builder")}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </div>
      <div>
        Role:
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={role}
          label="role"
          onChange={(e) => setRole(e.target.value)}
        >
          {Object.values(roles).map((i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <TextField
          id="reg-id"
          label="registration-id"
          value={registrationId}
          onChange={(e) => setRegistrationId(e.target.value)}
        />
      </div>
      <div>
        <TextField
          id="instance-id"
          label="instance-id"
          value={instanceId}
          onChange={(e) => setInstanceId(e.target.value)}
        />
      </div>
      <div>
        <TextField
          id="user-id"
          label="user"
          value={userId}
          type="number"
          InputProps={{ inputProps: { min: 1, max: 50 } }}
          onChange={(e) => setUserId(e.target.value)}
        />
      </div>
      <div></div>
      <span style={{ color: "red" }}>{error}</span>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {quizzes.map((quiz) => (
            <tr key={quiz.quizId}>
              <td>{quiz.quiz?.title || "Title Not Found."}</td>
              <td>{new Date(quiz.createdAt).toLocaleString("en-US")}</td>
              <td>{new Date(quiz.updatedAt).toLocaleString("en-US")}</td>
              <td>
                <IconButton
                  onClick={() => handleSSO(role, quiz.quizId, "quiz")}
                  size="large"
                  aria-label="Take Quiz"
                  color="inherit"
                >
                  <ArrowForwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleSSO(role, quiz.quizId, "builder")}
                  size="large"
                  aria-label="Edit Quiz"
                  color="info"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={() => handleSSO(role, quiz.quizId, "results")}
                >
                  <ManageAccountsIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Delete Quiz"
                  color="error"
                  onClick={() => handleSSO(role, null, "builder", quiz.quizId)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <a href={`${process.env.REACT_APP_API_URL}/docs`}>Docs</a>
      </div>
    </div>
  );
}
