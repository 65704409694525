import { useParams } from "react-router-dom";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import { useEffect, useState } from "react";
import { getQuiz } from "../../../../api/quiz";
import {
  QuestionRadiogroupModel,
  QuestionCheckboxModel,
  QuestionMatrixModel,
  QuestionMatrixDropdownModel,
  QuestionTextModel,
  QuestionBooleanModel,
  QuestionDropdownModel,
  QuestionImagePickerModel,
} from "survey-core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import * as Survey from "survey-core";
import * as ReactSurvey from "survey-react-ui";
import * as css from "survey-core/defaultV2.css";

import "./PreviewTab.css";
import { Switch } from "@mui/material";

Survey.StylesManager.applyTheme("defaultV2");

const PreviewTab = () => {
  const [model, setModel] = useState(undefined);
  const [error, setError] = useState("");
  const [completed, setCompleted] = useState(false);
  const [qData, setQData] = useState();

  const { quizId } = useParams();

  useEffect(() => {
    const setQuizModel = async () => {
      try {
        const quizData = await getQuiz(quizId);
        if (quizData?.quiz?.pages?.[0]) {
          setQData(quizData);
          let newModel = new ReactSurvey.Model(quizData.quiz);
          setModel(newModel);
        } else {
          setError("No Quiz data found.");
        }
      } catch (err) {
        setError(err.message || "Unknown Error Occurred");
      }
    };

    setQuizModel();
  }, [quizId]);

  const correctStr = "Correct";
  const inCorrectStr = "Incorrect";
  function getTextHtml(text, corrAnswer) {
    let corrIndex = text.lastIndexOf(correctStr);
    let incorrIndex = text.lastIndexOf(inCorrectStr);
    if (!text || (corrIndex < 0 && incorrIndex < 0)) return undefined;
    let resHtml = "";
    if (incorrIndex > -1 && corrAnswer && corrAnswer.length > 0) {
      resHtml +=
        text.substring(0, incorrIndex) +
        "<span style='color: red'>Incorrect</span><br/> <span style='color: green'>(Correct Answer: " +
        corrAnswer +
        ")</span>";
    } else if (incorrIndex > -1) {
      resHtml +=
        text.substring(0, incorrIndex) +
        "<span style='color: red'>Incorrect</span>";
    } else if (corrIndex > -1) {
      resHtml +=
        text.substring(0, corrIndex) +
        "<span style='color: green'>Correct </span>";
    }
    return resHtml;
  }
  function isAnswerCorrect(q) {
    const right = q.correctAnswer;
    if (!right || q.isEmpty()) return undefined;
    var left = q.value;
    if (
      q instanceof QuestionMatrixModel ||
      q instanceof QuestionMatrixDropdownModel
    ) {
      return _.isEqual(left, right);
    }
    if (!Array.isArray(right)) return right === left;
    if (!Array.isArray(left)) left = [left];
    if (left.length !== right.length) return false;
    for (var i = 0; i < left.length; i++) {
      if (right.indexOf(left[i]) < 0) return false;
    }
    return true;
  }

  function renderCorrectAnswer(q) {
    if (!q || !q.correctAnswer) return;
    const isCorrect = isAnswerCorrect(q);
    if (!q.prevTitle) {
      q.prevTitle = q.title;
    }
    if (isCorrect === undefined) {
      q.title = q.prevTitle;
    }
    q.title = q.prevTitle + " " + (isCorrect ? correctStr : inCorrectStr);
  }

  if (model && completed) {
    model.getAllQuestions().forEach((q) => renderCorrectAnswer(q));

    model.onTextMarkdown.add((sender, options) => {
      let corrAnswer = "";
      if (options.element instanceof QuestionTextModel) {
        corrAnswer = options.element.correctAnswer;
      } else if (options.element instanceof QuestionBooleanModel) {
        if (options.element.correctAnswer)
          corrAnswer = options.element.labelTrue;
        else corrAnswer = options.element.lableFalse;
      } else if (options.element instanceof QuestionDropdownModel) {
        let c = options.element.choices.find(
          (i) => i.value === options.element.correctAnswer
        );
        if (c) corrAnswer = c.text;
      } else if (options.element instanceof QuestionImagePickerModel) {
        corrAnswer = options.element.correctAnswer;
      } else if (options.element instanceof QuestionRadiogroupModel) {
        if (options.element.correctAnswer) {
          let selectedVal = options.element.selectedItem?.value;
          let selectedTxt = options.element.selectedItem?.text;
          let c = options.element.choices.find(
            (i) => i.value === options.element.correctAnswer
          );
          if (c) corrAnswer = c.text;
          if (options.name === "text" && selectedTxt === options.text) {
            if (options.element.correctAnswer === selectedVal) {
              let text = options.text;
              let html = `<span style="display: flex">${text}  ${ReactDOMServer.renderToString(
                <CheckBoxIcon color="success" />
              )}</span>`;
              options.html = html;
            } else {
              let text = options.text;
              let html = `<span style="display: flex">${text}  ${ReactDOMServer.renderToString(
                <DisabledByDefaultIcon sx={{ color: "red" }} />
              )}</span>`;
              options.html = html;
            }
          }
        }
      } else if (options.element instanceof QuestionCheckboxModel) {
        let corrAns = options.element.correctAnswer;
        if (corrAns) {
          let selectedTxt = options.element.selectedItems?.map((i) => i.text);
          let c = options.element.choices.filter((i) =>
            corrAns.includes(i.value)
          );
          if (c) corrAnswer = c.map((i) => i.text).toString();
          if (options.name === "text" && selectedTxt.includes(options.text)) {
            let corrTxt = options.element.selectedItems?.map((i) => {
              if (corrAns.includes(i.value)) return i.text;
              else return undefined;
            });
            if (corrTxt.includes(options.text)) {
              let text = options.text;
              let html = `<span style="display: flex">${text} ${ReactDOMServer.renderToString(
                <CheckBoxIcon color="success" />
              )}</span>`;
              options.html = html;
            } else {
              let text = options.text;
              let html = `<span style="display: flex">${text} ${ReactDOMServer.renderToString(
                <DisabledByDefaultIcon sx={{ color: "red" }} />
              )}</span>`;
              options.html = html;
            }
          }
        }
      } else if (options.element instanceof QuestionMatrixModel) {
        let ans = "";
        let c = options.element.correctAnswer;
        if (c) {
          for (const [k, v] of Object.entries(c)) {
            ans += `[${k}, ${v}]; `;
          }
          corrAnswer = ans;
        }
      } else if (options.element instanceof QuestionMatrixDropdownModel) {
        let c = options.element.correctAnswer;
        if (c) {
          let ans = "";
          for (const [r, rV] of Object.entries(c)) {
            for (const [col, v] of Object.entries(rV)) {
              ans += `[${r}, ${col}: ${v} ]; `;
            }
          }
          corrAnswer = ans;
        }
      }
      let text = options.text;
      let html = getTextHtml(text, corrAnswer);
      if (!!html) {
        options.html = html;
      }
    });
  }

  const showAnswerSheet = (canShow) => {
    if (canShow) {
      setModel(new ReactSurvey.Model());
      model.getAllQuestions().forEach((q) => (q.value = q.correctAnswer));
      model.clear(false);
      model.mode = "display";
      model.questionsOnPageMode = "singlePage";
      setModel(model);
      setCompleted(true);
    } else {
      setCompleted(false);
      setModel(new ReactSurvey.Model(qData.quiz));
    }
  };

  const onComplete = async (survey, options) => {
    let rightAnswers = survey.getCorrectedAnswerCount();
    let questionCount = survey.getQuizQuestionCount();
    let grade = Math.round((rightAnswers / questionCount) * 100);
    if (isNaN(grade)) grade = undefined; // if grade is NaN

    // review results
    survey.clear(false);
    survey.mode = "display";
    survey.questionsOnPageMode = "singlePage";
    setCompleted(true);
  };

  if (error) return <div>{error}</div>;

  if (model) {
    return (
      <>
        <div style={{ textAlign: "right", paddingRight: 15 }}>
          <Switch onChange={(e) => showAnswerSheet(e.target.checked)} /> Show
          Answers
        </div>
        <ReactSurvey.Survey model={model} onComplete={onComplete} css={css} />
      </>
    );
  }
  return <div>loading</div>;
};

export default PreviewTab;
